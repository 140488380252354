<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-alert
          variant="primary"
          class="p-1"
          :show="false"
        >
          <strong>{{ $t('error_active_orders') }}</strong>
        </b-alert>
      </b-col>
    </b-row>

    <HyperMarketOrders />
  </div>
</template>

<script>
/* eslint-disable-next-line */
import { HyperMarketOrders } from '@/components'

export default {
  name: 'HyperMarket',
  components: {
    HyperMarketOrders,
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
